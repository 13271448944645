let fields = [
  {
    name: 'bulk',
    width: 70,
    sortable: false,
    visible: false,
  },
  {
    name: 'completed',
    width: null,
    sortable: true,
    visible: true,
    require: true,
  },
  {
    name: 'endAt',
    width: 150,
    sortable: true,
    visible: true,
  },
  {
    name: 'taskName',
    width: 500,
    sortable: true,
    visible: true,
    require: true,
  },
  {
    name: 'projectName',
    width: 420,
    sortable: true,
    visible: true,
  },
  {
    name: 'projectNumber',
    width: 200,
    sortable: true,
    visible: true,
  },
  {
    name: 'users',
    width: null,
    sortable: false,
    visible: true,
  },
  {
    name: 'synchronize',
    width: null,
    sortable: true,
    visible: true,
  },
  {
    name: 'attachment',
    width: 100,
    sortable: true,
    visible: true,
  },
  {
    name: 'note',
    width: 100,
    sortable: true,
    visible: true,
  },
  {
    name: 'shielded',
    width: null,
    sortable: true,
    visible: false,
  },
  {
    name: 'startAt',
    width: 170,
    sortable: true,
    visible: false,
  },
  {
    name: 'projectStatuses',
    width: 150,
    sortable: true,
    visible: false,
  },
  {
    name: 'labels',
    width: 200,
    sortable: false,
    visible: false,
  },
  {
    name: 'forms',
    width: 100,
    sortable: true,
    visible: false,
  },
]

fields = Object.fromEntries(
  Object.entries(fields).map(([key, val]) => {
    let name = val.name,
      obj = { ...val, position: Number(key) }

    delete obj.name

    return [name, obj]
  })
)

export default {
  id: null,
  statuses: [],
  projectStatuses: [],
  users: [],
  resources: [],
  labels: [],
  projects: [],
  sort: {
    type: 'nameZA',
    field: 'endAt',
  },
  selectTimeRange: 'scheduled',
  fields,
}
