import { isString } from 'lodash';

export const parseIntegerArrayParameter = (value) => {
  return value.split(',').map(number => Number(number.trim()));
}

export const isIntegerArrayParameter = (value) => {
  try {
    return isString(value) && parseIntegerArrayParameter(value).every(number => Number.isInteger(number));
  } catch {
    return false;
  }
}
